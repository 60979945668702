import { CDN_PATH } from "../config/config";

export type ClubInformation = {
  domain: string;
  clubCode: string;
  clubName: string;
  hometown: string;
  division: string;
  logo: {
    url: string;
    width: number;
    height: number;
  };
  primary: {
    main: string;
    light: string;
    dark: string;
  };
  secondary: string;
  workspace: {
    bgLight: string;
    bgDark: string;
    hover: string;
    focus: string;
  };
  clubId: string;
  clubGradeSort?: string;
  clubGradePrecision?: number;
  clubGradeScalingFactor?: number;
  positions: ClubPosition[];
};

export type ClubPosition = {
  name: string;
  group: string;
};

export const clubInformation: ClubInformation[] = [
  {
    domain: "cardinals.nfl.com",
    clubCode: "ARZ",
    clubName: "Arizona Cardinals",
    hometown: "Arizona",
    division: "NFC West",
    logo: {
      url: `${CDN_PATH}/pro/logos/transparent/ARZ.gif`,
      width: 600,
      height: 401,
    },
    primary: {
      main: "#97233F",
      light: "#E7C0CA",
      dark: "#56081B",
    },
    secondary: "#000000",
    workspace: {
      bgLight: "#F4EFF1",
      bgDark: "#F4EFF1",
      hover: "#F9F2F3",
      focus: "#D5A7B2",
    },
    clubId: "29bc477a-e5b7-57dd-b73c-ad9783e85b3c",
    positions: [],
  },
  {
    domain: "falcons.nfl.com",
    clubCode: "ATL",
    clubName: "Atlanta Falcons",
    hometown: "Atlanta",
    division: "NFC South",

    logo: {
      url: `${CDN_PATH}/pro/logos/transparent/ATL.gif`,
      width: 600,
      height: 401,
    },
    primary: {
      main: "#A71930",
      light: "#EAC1C8",
      dark: "#530512",
    },
    secondary: "#000000",
    workspace: {
      bgLight: "#F5EFF0",
      bgDark: "#DFDCDC",
      hover: "#FAF1F3",
      focus: "#DCA3AC",
    },
    clubId: "05b24043-bb4c-50e7-88f6-f2e43146b5a2",
    positions: [],
  },
  {
    domain: "ravens.nfl.com",
    clubCode: "BLT",
    clubName: "Baltimore Ravens",
    hometown: "Baltimore",
    division: "AFC North",
    logo: {
      url: `${CDN_PATH}/pro/logos/transparent/BLT.gif`,
      width: 600,
      height: 401,
    },
    primary: {
      main: "#1A195F",
      light: "#C9C8EC",
      dark: "#030328",
    },
    secondary: "#000000",
    workspace: {
      bgLight: "#EFEFF2",
      bgDark: "#DCDCDD",
      hover: "#F1F1F5",
      focus: "#A3A3BF",
    },
    clubId: "a2c18dc9-2b95-595a-91c1-c07d8bf10ffc",
    positions: [],
  },
  {
    domain: "bills.nfl.com",
    clubCode: "BUF",
    clubName: "Buffalo Bills",
    hometown: "Buffalo",
    division: "AFC East",
    logo: {
      url: `${CDN_PATH}/pro/logos/transparent/BUF.gif`,
      width: 600,
      height: 401,
    },
    primary: {
      main: "#00338D",
      light: "#C6D4EC",
      dark: "#021536",
    },
    secondary: "#C60C30",
    workspace: {
      bgLight: "#EEF0F4",
      bgDark: "#DCDDDE",
      hover: "#F0F3F8",
      focus: "#99ADD1",
    },
    clubId: "645fddd1-df20-5323-93e4-c7c176baa507",
    positions: [],
  },
  {
    domain: "panthers.nfl.com",
    clubCode: "CAR",
    clubName: "Carolina Panthers",
    hometown: "Carolina",
    division: "NFC South",
    logo: {
      url: `${CDN_PATH}/pro/logos/transparent/CAR.gif`,
      width: 600,
      height: 401,
    },
    primary: {
      main: "#0085CA",
      light: "#D9EDF7",
      dark: "#075177",
    },
    secondary: "#BFC0BF",
    workspace: {
      bgLight: "#F3F6F7",
      bgDark: "#D7DCDF",
      hover: "#F0F8FC",
      focus: "#99CEEA",
    },
    clubId: "b0afc250-bc45-565b-ab31-c09e2d5209e8",
    clubGradeSort: "asc",
    clubGradePrecision: 2,
    positions: [
      { name: "DC", group: "Defense" },
      { name: "DE", group: "Defense" },
      { name: "DS", group: "Defense" },
      { name: "ILB", group: "Defense" },
      { name: "OLB", group: "Defense" },
      { name: "NKL", group: "Defense" },
      { name: "NT", group: "Defense" },
      { name: "QB", group: "Offense" },
      { name: "WR", group: "Offense" },
      { name: "SLOT", group: "Offense" },
      { name: "RB", group: "Offense" },
      { name: "FB/F", group: "Offense" },
      { name: "TE", group: "Offense" },
      { name: "OC", group: "Offense" },
      { name: "OG", group: "Offense" },
      { name: "LT", group: "Offense" },
      { name: "RT", group: "Offense" },
      { name: "SPEC", group: "Special Teams" },
      { name: "LS", group: "Special Teams" },
      { name: "PK", group: "Special Teams" },
      { name: "PT", group: "Special Teams" },
    ],
  },
  {
    domain: "bears.nfl.com",
    clubCode: "CHI",
    clubName: "Chicago Bears",
    hometown: "Chicago",
    division: "NFC North",
    logo: {
      url: `${CDN_PATH}/pro/logos/transparent/CHI.gif`,
      width: 600,
      height: 401,
    },
    primary: {
      main: "#0B162A",
      light: "#C7D3EA",
      dark: "#010712",
    },
    secondary: "#DD4814",
    workspace: {
      bgLight: "#EFEFF0",
      bgDark: "#DCDCDC",
      hover: "#F0F1F2",
      focus: "#9DA2AA",
    },
    clubId: "a939ecc7-fdbe-5f71-af12-b480efb5ffeb",
    positions: [],
  },
  {
    domain: "bengals.nfl.com",
    clubCode: "CIN",
    clubName: "Cincinnati Bengals",
    hometown: "Cincinnati",
    division: "AFC North",
    logo: {
      url: `${CDN_PATH}/pro/logos/transparent/CIN.gif`,
      width: 600,
      height: 401,
    },
    primary: {
      main: "#FB4F14",
      light: "#F1CDC1",
      dark: "#992700",
    },
    secondary: "#FB4F14",
    workspace: {
      bgLight: "#F8F1EF",
      bgDark: "#E1DDDC",
      hover: "#FFF4F1",
      focus: "#FDB9A1",
    },
    clubId: "d6ee26d1-1c8a-5599-a3bd-1537e7de85bc",
    positions: [],
  },
  {
    domain: "browns.nfl.com",
    clubCode: "CLV",
    clubName: "Cleveland Browns",
    hometown: "Cleveland",
    division: "AFC North",
    logo: {
      url: `${CDN_PATH}/pro/logos/transparent/CLV.gif`,
      width: 600,
      height: 401,
    },
    primary: {
      main: "#311D00",
      light: "#E6C493",
      dark: "#211400",
    },
    secondary: "#E34912",
    workspace: {
      bgLight: "#F0EFEE",
      bgDark: "#DDDCDC",
      hover: "#F3F1F0",
      focus: "#ADA599",
    },
    clubId: "becdb626-1b97-5904-9eed-71842375bc39",
    positions: [],
  },
  {
    domain: "cowboys.nfl.com",
    clubCode: "DAL",
    clubName: "Dallas Cowboys",
    hometown: "Dallas",
    division: "NFC East",
    logo: {
      url: `${CDN_PATH}/pro/logos/transparent/DAL.gif`,
      width: 600,
      height: 401,
    },
    primary: {
      main: "#002244",
      light: "#C9D7E6",
      dark: "#00172E",
    },
    secondary: "#8C8B8A",
    workspace: {
      bgLight: "#EEEFF1",
      bgDark: "#DCDCDD",
      hover: "#F0F2F4",
      focus: "#99A7B4",
    },
    clubId: "1afbdd15-d429-5bcb-a55b-af7199345da5",
    positions: [],
  },
  {
    domain: "broncos.nfl.com",
    clubCode: "DEN",
    clubName: "Denver Broncos",
    hometown: "Denver",
    division: "AFC West",
    logo: {
      url: `${CDN_PATH}/pro/logos/transparent/DEN.gif`,
      width: 600,
      height: 401,
    },
    primary: {
      main: "#FB4F14",
      light: "#F1CDC1",
      dark: "#992700",
    },
    secondary: "#002244",
    workspace: {
      bgLight: "#F8F1EF",
      bgDark: "#E1DDDC",
      hover: "#FFF4F1",
      focus: "#FDB9A1",
    },
    clubId: "30a0f7e1-9843-5edd-8cf1-c60918b7f861",
    positions: [],
  },
  {
    domain: "lions.nfl.com",
    clubCode: "DET",
    clubName: "Detroit Lions",
    hometown: "Detroit",
    division: "NFC North",
    logo: {
      url: `${CDN_PATH}/pro/logos/transparent/DET.gif`,
      width: 600,
      height: 401,
    },
    primary: {
      main: "#0076B6",
      light: "#D0EEFE",
      dark: "#00324D",
    },
    secondary: "#C5C7CF",
    workspace: {
      bgLight: "#EEF3F5",
      bgDark: "#DCDEDF",
      hover: "#F0F7FB",
      focus: "#99C8E2",
    },
    clubId: "9bbd6453-9703-5015-8a11-1a607b8805bd",
    positions: [],
  },
  {
    domain: "packers.nfl.com",
    clubCode: "GB",
    clubName: "Green Bay Packers",
    hometown: "Green Bay",
    division: "NFC North",
    logo: {
      url: `${CDN_PATH}/pro/logos/transparent/GB.gif`,
      width: 600,
      height: 401,
    },
    primary: {
      main: "#183028",
      light: "#BCE7D9",
      dark: "#021A12",
    },
    secondary: "#FFCC00",
    workspace: {
      bgLight: "#EFF0F0",
      bgDark: "#DCDCDC",
      hover: "#F1F3F2",
      focus: "#A3ACA9",
    },
    clubId: "63d59843-d136-5f8b-9c55-de09a20ab321",
    positions: [],
  },
  {
    domain: "texans.nfl.com",
    clubCode: "HST",
    clubName: "Houston Texans",
    hometown: "Houston",
    division: "AFC South",
    logo: {
      url: `${CDN_PATH}/pro/logos/transparent/HST.gif`,
      width: 600,
      height: 401,
    },
    primary: {
      main: "#03202F",
      light: "#AAD0E4",
      dark: "#010E15",
    },
    secondary: "#B31B34",
    workspace: {
      bgLight: "#EEEFF0",
      bgDark: "#DCDCDC",
      hover: "#F0F2F3",
      focus: "#9AA6AC",
    },
    clubId: "e871178d-ca00-52ff-9e93-e3f7a8a9bc9f",
    positions: [],
  },
  {
    domain: "colts.nfl.com",
    clubCode: "IND",
    clubName: "Indianapolis Colts",
    hometown: "Indianapolis",
    division: "AFC South",
    logo: {
      url: `${CDN_PATH}/pro/logos/transparent/IND.gif`,
      width: 600,
      height: 401,
    },
    primary: {
      main: "#002C5F",
      light: "#C4D7EE",
      dark: "#000F21",
    },
    secondary: "#FFFFFF",
    workspace: {
      bgLight: "#EEF0F2",
      bgDark: "#DCDCDD",
      hover: "#F0F2F5",
      focus: "#99ABBF",
    },
    clubId: "632cbb59-f592-5648-b181-6b23bdf9d85d",
    positions: [],
  },
  {
    domain: "jaguars.nfl.com",
    clubCode: "JAX",
    clubName: "Jacksonville Jaguars",
    hometown: "Jacksonville",
    division: "AFC South",
    logo: {
      url: `${CDN_PATH}/pro/logos/transparent/JAX.gif`,
      width: 600,
      height: 401,
    },
    primary: {
      main: "#006778",
      light: "#D9E8EB",
      dark: "#0F282B",
    },
    secondary: "#D0B239",
    workspace: {
      bgLight: "#F3F5F5",
      bgDark: "#D7DBDC",
      hover: "#F0F6F7",
      focus: "#99C2C9",
    },
    clubId: "b0d7698e-edf7-5afe-98fe-85827218adb9",
    clubGradeScalingFactor: 10,
    positions: [],
  },
  {
    domain: "chiefs.nfl.com",
    clubCode: "KC",
    clubName: "Kansas City Chiefs",
    hometown: "Kansas City",
    division: "AFC West",
    logo: {
      url: `${CDN_PATH}/pro/logos/transparent/KC.gif`,
      width: 600,
      height: 401,
    },
    primary: {
      main: "#E31837",
      light: "#FACAD2",
      dark: "#540612",
    },
    secondary: "#F2C800",
    workspace: {
      bgLight: "#F7EFF0",
      bgDark: "#E0DCDD",
      hover: "#FDF1F3",
      focus: "#F4A3AF",
    },
    clubId: "3baabdb4-8c73-58f8-8e87-8db435aefb16",
    positions: [],
  },
  {
    domain: "rams.nfl.com",
    clubCode: "LA",
    clubName: "Los Angeles Rams",
    hometown: "Los Angeles",
    division: "NFC West",
    logo: {
      url: `${CDN_PATH}/pro/logos/transparent/LA.gif`,
      width: 600,
      height: 401,
    },
    primary: {
      main: "#003594",
      light: "#BCCCEA",
      dark: "#021333",
    },
    secondary: "#C6A875",
    workspace: {
      bgLight: "#EEF0F4",
      bgDark: "#DCDDDE",
      hover: "#F0F3F9",
      focus: "#99AED4",
    },
    clubId: "252e01fc-2471-563e-aa87-4876e74fbf06",
    positions: [],
  },
  {
    domain: "chargers.nfl.com",
    clubCode: "LAC",
    clubName: "Los Angeles Chargers",
    hometown: "Los Angeles",
    division: "AFC West",
    logo: {
      url: `${CDN_PATH}/pro/logos/transparent/LAC.gif`,
      width: 600,
      height: 401,
    },
    primary: {
      main: "#002A5E",
      light: "#BFD2E9",
      dark: "#00132C",
    },
    secondary: "#EEC607",
    workspace: {
      bgLight: "#EEF0F2",
      bgDark: "#DCDCDD",
      hover: "#F0F2F5",
      focus: "#99AABF",
    },
    clubId: "dd0c9c81-33c9-5cc9-bee2-7c26a6327f6c",
    positions: [],
  },
  {
    domain: "raiders.nfl.com",
    clubCode: "LV",
    clubName: "Las Vegas Raiders",
    hometown: "Las Vegas",
    division: "AFC West",
    logo: {
      url: `${CDN_PATH}/pro/logos/transparent/OAK.gif`,
      width: 600,
      height: 401,
    },
    primary: {
      main: "#000000",
      light: "#E0E0E0",
      dark: "#000000",
    },
    secondary: "#000000",
    workspace: {
      bgLight: "#EEEEEE",
      bgDark: "#DCDCDC",
      hover: "#F0F0F0",
      focus: "#999999",
    },
    clubId: "bb3667cc-9ac6-5170-9572-2b840882facd",
    positions: [],
  },
  {
    domain: "dolphins.nfl.com",
    clubCode: "MIA",
    clubName: "Miami Dolphins",
    hometown: "Miami",
    division: "AFC East",
    logo: {
      url: `${CDN_PATH}/pro/logos/transparent/MIA.gif`,
      width: 600,
      height: 401,
    },
    primary: {
      main: "#008E97",
      light: "#CEF4F6",
      dark: "#004448",
    },
    secondary: "#015679",
    workspace: {
      bgLight: "#EEF4F4",
      bgDark: "#DCDEDF",
      hover: "#F0F8F9",
      focus: "#99D2D5",
    },
    clubId: "4857a921-f6c9-5357-8527-f8589332184b",
    positions: [],
  },
  {
    domain: "vikings.nfl.com",
    clubCode: "MIN",
    clubName: "Minnesota Vikings",
    hometown: "Minnesota",
    division: "NFC North",
    logo: {
      url: `${CDN_PATH}/pro/logos/transparent/MIN.gif`,
      width: 600,
      height: 401,
    },
    primary: {
      main: "#4F2683",
      light: "#E3D2F7",
      dark: "#170330",
    },
    secondary: "#FFC52F",
    workspace: {
      bgLight: "#F1F0F3",
      bgDark: "#DDDCDE",
      hover: "#F4F2F8",
      focus: "#B9A8CD",
    },
    clubId: "2315da89-83d2-5174-b6a7-1e81d98032a2",
    positions: [],
  },
  {
    domain: "patriots.nfl.com",
    clubCode: "NE",
    clubName: "New England Patriots",
    hometown: "New England",
    division: "AFC East",
    logo: {
      url: `${CDN_PATH}/pro/logos/transparent/NE.gif`,
      width: 600,
      height: 401,
    },
    primary: {
      main: "#002244",
      light: "#C9D7E6",
      dark: "#00172E",
    },
    secondary: "#D6D6D6",
    workspace: {
      bgLight: "#EEEFF1",
      bgDark: "#DCDCDD",
      hover: "#F0F2F4",
      focus: "#99A7B4",
    },
    clubId: "cb8fb75c-0a4c-5080-8580-9b2d46e98591",
    positions: [
      { name: "CB", group: "Defense" },
      { name: "DE", group: "Defense" },
      { name: "DT", group: "Defense" },
      { name: "LB", group: "Defense" },
      { name: "SAF", group: "Defense" },
      { name: "QB", group: "Offense" },
      { name: "WR", group: "Offense" },
      { name: "RB", group: "Offense" },
      { name: "FB", group: "Offense" },
      { name: "TE", group: "Offense" },
      { name: "OC", group: "Offense" },
      { name: "OG", group: "Offense" },
      { name: "OT", group: "Offense" },
      { name: "SP", group: "Special Teams" },
    ],
  },
  {
    domain: "saints.nfl.com",
    clubCode: "NO",
    clubName: "New Orleans Saints",
    hometown: "New Orleans",
    division: "NFC South",
    logo: {
      url: `${CDN_PATH}/pro/logos/transparent/NO.gif`,
      width: 600,
      height: 401,
    },
    primary: {
      main: "#000000",
      light: "#E0E0E0",
      dark: "#000000",
    },
    secondary: "#000000",
    workspace: {
      bgLight: "#EEEEEE",
      bgDark: "#DCDCDC",
      hover: "#F0F0F0",
      focus: "#999999",
    },
    clubId: "361e23bb-82f8-554d-b60d-24ac2d7679c3",
    positions: [],
  },
  {
    domain: "giants.nfl.com",
    clubCode: "NYG",
    clubName: "New York Giants",
    hometown: "New York",
    division: "NFC East",
    logo: {
      url: `${CDN_PATH}/pro/logos/transparent/NYG.gif`,
      width: 600,
      height: 401,
    },
    primary: {
      main: "#012352",
      light: "#CDDBEE",
      dark: "#001027",
    },
    secondary: "#CA001A",
    workspace: {
      bgLight: "#EEEFF1",
      bgDark: "#DCDCDD",
      hover: "#F0F2F5",
      focus: "#99A7BA",
    },
    clubId: "8f5bf2a9-6ce9-5164-988e-2e0062f32090",
    positions: [],
  },
  {
    domain: "jets.nfl.com",
    clubCode: "NYJ",
    clubName: "New York Jets",
    hometown: "New York",
    division: "AFC East",
    logo: {
      url: `${CDN_PATH}/pro/logos/transparent/NYJ.gif`,
      width: 600,
      height: 401,
    },
    primary: {
      main: "#125740",
      light: "#C7EEE1",
      dark: "#01281B",
    },
    secondary: "#FFFFFF",
    workspace: {
      bgLight: "#EFF2F1",
      bgDark: "#DCDDDD",
      hover: "#F1F5F4",
      focus: "#A0BCB3",
    },
    clubId: "84f58345-4a56-5c30-92b4-7365ac46b68f",
    positions: [],
  },
  {
    domain: "eagles.nfl.com",
    clubCode: "PHI",
    clubName: "Philadelphia Eagles",
    hometown: "Philadelphia",
    division: "NFC East",
    logo: {
      url: `${CDN_PATH}/pro/logos/transparent/PHI.gif`,
      width: 600,
      height: 401,
    },
    primary: {
      main: "#004C54",
      light: "#B6E2E7",
      dark: "#001B1E",
    },
    secondary: "#000000",
    workspace: {
      bgLight: "#EEF1F1",
      bgDark: "#DCDDDD",
      hover: "#F0F4F5",
      focus: "#99B7BB",
    },
    clubId: "eb0beb8f-2a1e-51e2-9ab2-793f72994559",
    positions: [],
  },
  {
    domain: "steelers.nfl.com",
    clubCode: "PIT",
    clubName: "Pittsburgh Steelers",
    hometown: "Pittsburgh",
    division: "AFC North",
    logo: {
      url: `${CDN_PATH}/pro/logos/transparent/PIT.gif`,
      width: 600,
      height: 401,
    },
    primary: {
      main: "#FFB612",
      light: "#FFF1D2",
      dark: "#000000",
    },
    secondary: "#F2C800",
    workspace: {
      bgLight: "#F8F5EF",
      bgDark: "#E1DFDC",
      hover: "#FFFBF1",
      focus: "#FFE2A0",
    },
    clubId: "995a2dd0-93bd-5651-a60d-6708b62b17b3",
    positions: [],
  },
  {
    domain: "seahawks.nfl.com",
    clubCode: "SEA",
    clubName: "Seattle Seahawks",
    hometown: "Seattle",
    division: "NFC West",
    logo: {
      url: `${CDN_PATH}/pro/logos/transparent/SEA.gif`,
      width: 600,
      height: 401,
    },
    primary: {
      main: "#002244",
      light: "#C9D7E6",
      dark: "#00172E",
    },
    secondary: "#4EAE47",
    workspace: {
      bgLight: "#EEEFF1",
      bgDark: "#DCDCDD",
      hover: "#F0F2F4",
      focus: "#99A7B4",
    },
    clubId: "bc17557b-b749-58b5-9404-8682a796a9d2",
    positions: [],
  },
  {
    domain: "49ers.nfl.com",
    clubCode: "SF",
    clubName: "San Francisco 49ers",
    hometown: "San Francisco",
    division: "NFC West",
    logo: {
      url: `${CDN_PATH}/pro/logos/transparent/SF.gif`,
      width: 600,
      height: 401,
    },
    primary: {
      main: "#AA0000",
      light: "#EABEBE",
      dark: "#430000",
    },
    secondary: "#E6BE8A",
    workspace: {
      bgLight: "#F5EEEE",
      bgDark: "#DFDCDC",
      hover: "#FAF0F0",
      focus: "#DD9999",
    },
    clubId: "88397334-0092-5b55-8ce1-2bd467e0edf8",
    positions: [
      { name: "CB", group: "Defense" },
      { name: "DE", group: "Defense" },
      { name: "DT", group: "Defense" },
      { name: "LB", group: "Defense" },
      { name: "SAF", group: "Defense" },
      { name: "QB", group: "Offense" },
      { name: "WR", group: "Offense" },
      { name: "RB", group: "Offense" },
      { name: "TE", group: "Offense" },
      { name: "OC", group: "Offense" },
      { name: "OG", group: "Offense" },
      { name: "OT", group: "Offense" },
      { name: "ST", group: "Special Teams" },
    ],
  },
  {
    domain: "buccaneers.nfl.com",
    clubCode: "TB",
    clubName: "Tampa Bay Buccaneers",
    hometown: "Tampa Bay",
    division: "NFC South",
    logo: {
      url: `${CDN_PATH}/pro/logos/transparent/TB.gif`,
      width: 600,
      height: 401,
    },
    primary: {
      main: "#D50A0A",
      light: "#F8D2D2",
      dark: "#2F0101",
    },
    secondary: "#89765F",
    workspace: {
      bgLight: "#F7EEEE",
      bgDark: "#E0DCDC",
      hover: "#FCF0F0",
      focus: "#EE9D9D",
    },
    clubId: "07f46f9d-1c41-534e-ab68-574e82c94a46",
    positions: [],
  },
  {
    domain: "titans.nfl.com",
    clubCode: "TEN",
    clubName: "Tennessee Titans",
    hometown: "Tennessee",
    division: "AFC South",
    logo: {
      url: `${CDN_PATH}/pro/logos/transparent/TEN.gif`,
      width: 600,
      height: 401,
    },
    primary: {
      main: "#0C2340",
      light: "#D8E4F3",
      dark: "#010F1F",
    },
    secondary: "#000080",
    workspace: {
      bgLight: "#EEEFF1",
      bgDark: "#DCDCDD",
      hover: "#F0F2F4",
      focus: "#9EA7B3",
    },
    clubId: "b4a5ebdf-c1ab-5046-9985-a69f83a44ace",
    positions: [],
  },
  {
    domain: "commanders.nfl.com",
    clubCode: "WAS",
    clubName: "Washington Commanders",
    hometown: "Washington",
    division: "NFC East",
    logo: {
      url: `${CDN_PATH}/pro/logos/transparent/WAS.gif`,
      width: 600,
      height: 401,
    },
    primary: {
      main: "#773141",
      light: "#EEC8D1",
      dark: "#200209",
    },
    secondary: "#FFB612",
    workspace: {
      bgLight: "#F3F0F1",
      bgDark: "#DEDDDD",
      hover: "#F7F3F4",
      focus: "#C9ADB3",
    },
    clubId: "e3003a6c-2896-5b55-9ede-2d9a2b16dca2",
    positions: [],
  },
];

export const getClubFromCode = (clubCode?: string) => {
  const club = clubInformation.find((club) => club.clubCode === clubCode);
  return club;
};

export const getClubCode = (clubId?: string) => {
  const club = clubInformation.find((club) => club.clubId === clubId);
  return club?.clubCode;
};

export const getClubName = (clubId?: string) => {
  const club = clubInformation.find((club) => club.clubId === clubId);
  return club?.clubName;
};

export const getClubId = (clubCode?: string) => {
  const club = clubInformation.find((club) => club.clubCode === clubCode);
  return club?.clubId;
};

export const clubGradeSortOrder = (clubCode: string) => {
  const club = clubInformation.find((club) => club.clubCode === clubCode);
  return club?.clubGradeSort === "asc" ? "asc" : "desc";
};

export const getClubGradeScalingFactor = (clubCode: string) => {
  const club = clubInformation.find((club) => club.clubCode === clubCode);
  return club?.clubGradeScalingFactor || 1;
};

export const getClubsByDivision = (division: string) => {
  return clubInformation.filter((club) => club.division === division);
};

export enum DivisionEnum {
  NFCSouth = "NFC South",
  NFCNorth = "NFC North",
  NFCEast = "NFC East",
  NFCWest = "NFC West",
  AFCSouth = "AFC South",
  AFCNorth = "AFC North",
  AFCEast = "AFC East",
  AFCWest = "AFC West",
}

export const getSideOfBall = (position: string) => {
  const positionFilters = [
    { sideOfBall: "Defense", name: "3 Tech" },
    { sideOfBall: "Defense", name: "3-4 DE" },
    { sideOfBall: "Defense", name: "3-4 IB" },
    { sideOfBall: "Defense", name: "3-4 NT" },
    { sideOfBall: "Defense", name: "3-4 OB" },
    { sideOfBall: "Defense", name: "4-3 DE" },
    { sideOfBall: "Defense", name: "BE" },
    { sideOfBall: "Defense", name: "CB" },
    { sideOfBall: "Defense", name: "DC" },
    { sideOfBall: "Defense", name: "DC-NICKEL" },
    { sideOfBall: "Defense", name: "DE" },
    { sideOfBall: "Defense", name: "DS" },
    { sideOfBall: "Defense", name: "DT" },
    { sideOfBall: "Defense", name: "EDGE" },
    { sideOfBall: "Defense", name: "EDGE-RUSH" },
    { sideOfBall: "Defense", name: "FS" },
    { sideOfBall: "Defense", name: "IB" },
    { sideOfBall: "Defense", name: "ILB" },
    { sideOfBall: "Defense", name: "LB" },
    { sideOfBall: "Defense", name: "LDC" },
    { sideOfBall: "Defense", name: "LDE" },
    { sideOfBall: "Defense", name: "LDE3" },
    { sideOfBall: "Defense", name: "LDT" },
    { sideOfBall: "Defense", name: "MIKE" },
    { sideOfBall: "Defense", name: "MLB" },
    { sideOfBall: "Defense", name: "MO" },
    { sideOfBall: "Defense", name: "NDC" },
    { sideOfBall: "Defense", name: "NCB" },
    { sideOfBall: "Defense", name: "NICKEL" },
    { sideOfBall: "Defense", name: "NKL" },
    { sideOfBall: "Defense", name: "NT" },
    { sideOfBall: "Defense", name: "OB" },
    { sideOfBall: "Defense", name: "OLB" },
    { sideOfBall: "Defense", name: "RDC" },
    { sideOfBall: "Defense", name: "RDE" },
    { sideOfBall: "Defense", name: "RDE3" },
    { sideOfBall: "Defense", name: "RDT" },
    { sideOfBall: "Defense", name: "SAF" },
    { sideOfBall: "Defense", name: "SAM" },
    { sideOfBall: "Defense", name: "SAM3" },
    { sideOfBall: "Defense", name: "SLB" },
    { sideOfBall: "Defense", name: "SLB-RUSH" },
    { sideOfBall: "Defense", name: "SS" },
    { sideOfBall: "Defense", name: "WILL" },
    { sideOfBall: "Defense", name: "WILL3" },
    { sideOfBall: "Defense", name: "WOLB" },

    { sideOfBall: "Offense", name: "C" },
    { sideOfBall: "Offense", name: "FB" },
    { sideOfBall: "Offense", name: "FB/F" },
    { sideOfBall: "Offense", name: "IB" },
    { sideOfBall: "Offense", name: "LG" },
    { sideOfBall: "Offense", name: "LT" },
    { sideOfBall: "Offense", name: "LWO" },
    { sideOfBall: "Offense", name: "OC" },
    { sideOfBall: "Offense", name: "OG" },
    { sideOfBall: "Offense", name: "OT" },
    { sideOfBall: "Offense", name: "QB" },
    { sideOfBall: "Offense", name: "RB" },
    { sideOfBall: "Offense", name: "RG" },
    { sideOfBall: "Offense", name: "RT" },
    { sideOfBall: "Offense", name: "RWO" },
    { sideOfBall: "Offense", name: "SLOT" },
    { sideOfBall: "Offense", name: "sWO" },
    { sideOfBall: "Offense", name: "SWR" },
    { sideOfBall: "Offense", name: "TE" },
    { sideOfBall: "Offense", name: "TEA" },
    { sideOfBall: "Offense", name: "TEB" },
    { sideOfBall: "Offense", name: "TE-F" },
    { sideOfBall: "Offense", name: "TE-Y" },
    { sideOfBall: "Offense", name: "WO" },
    { sideOfBall: "Offense", name: "WO-SLOT" },
    { sideOfBall: "Offense", name: "WR" },
    { sideOfBall: "Offense", name: "WO-SLOT" },
    { sideOfBall: "Offense", name: "SWO" },

    { sideOfBall: "Special Teams", name: "H" },
    { sideOfBall: "Special Teams", name: "K" },
    { sideOfBall: "Special Teams", name: "KR" },
    { sideOfBall: "Special Teams", name: "LS" },
    { sideOfBall: "Special Teams", name: "P" },
    { sideOfBall: "Special Teams", name: "PK" },
    { sideOfBall: "Special Teams", name: "PR" },
    { sideOfBall: "Special Teams", name: "PT" },
    { sideOfBall: "Special Teams", name: "RS" },
    { sideOfBall: "Special Teams", name: "SP" },
    { sideOfBall: "Special Teams", name: "SPEC" },
    { sideOfBall: "Special Teams", name: "ST" },
    { sideOfBall: "Special Teams", name: "ST-D" },
    { sideOfBall: "Special Teams", name: "ST-O" },
  ];

  return positionFilters.find((f) => f.name === position)?.sideOfBall ?? "Misc";
};
