import { ClubInformation, clubInformation } from "./clubStaticData";
import _ from "lodash";

function lookupByClubCode(clubCode: string) {
  if (clubCode === "bal") clubCode = "BLT";
  const c = clubInformation.find((a) => a.clubCode === clubCode.toUpperCase());
  if (!c)
    throw new Error(
      `Club lookup failed ${clubCode} ${clubInformation.map((c) => c.clubCode)}`
    );
  return c;
}

export const getClubData = _.memoize(lookupByClubCode);

export const getClubAttributesFromConfig = (
  clubCode?: string
): ClubInformation | null => {
  if (clubCode === "DEFAULT") {
    return null;
  }
  return clubInformation.filter((c) => c.clubCode == clubCode)[0];
};

export const getClubAttributesFromUsername = (
  userEmail?: string
): ClubInformation => {
  if (userEmail && !userEmail.endsWith("@sumersports.com")) {
    const domain = userEmail.split("@")[1];
    return clubInformation.filter((c) => c.domain == domain)[0];
  }
  const testAsNE = clubInformation.find((c) => c.clubCode === "NE");
  if (!testAsNE) throw new Error("no NE theme");
  return testAsNE;
};
