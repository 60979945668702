import { ButtonProps, Button as MUIButton, styled } from "@mui/material";

export enum ButtonSize {
  ExtraSmall = "extraSmall",
  Small = "small",
  Medium = "medium",
  Large = "large",
}

export enum ButtonType {
  Primary = "primary",
  Secondary = "secondary",
  Tertiary = "tertiary",
  Destructive = "destructive",
}

export interface MarvalButtonProps {
  buttonSize?: ButtonSize;
  buttonType?: ButtonType;
  dark?: boolean;
}

export const Button = (props: MarvalButtonProps & ButtonProps) => {
  const {
    children,
    buttonSize = ButtonSize.Medium,
    buttonType = ButtonType.Primary,
    dark,
    sx,
    ...rest
  } = props;

  const sizeProps = getSizeProps(buttonSize);
  const classname = `marval-${buttonType}-${buttonSize}${dark ? "-dark" : ""
    }-button`;
  let Component = Primary;

  if (dark) {
    Component = Dark;
  } else {
    switch (buttonType) {
      case ButtonType.Secondary:
        Component = Secondary;
        break;
      case ButtonType.Tertiary:
        Component = Tertiary;
        break;
      case ButtonType.Destructive:
        Component = Destructive;
        break;
      default:
        Component = Primary;
        break;
    }
  }

  return (
    <Component {...rest} sx={{ ...sizeProps, ...sx }} className={classname}>
      {children}
    </Component>
  );
};

const getSizeProps = (size: ButtonSize) => {
  switch (size) {
    case ButtonSize.ExtraSmall:
      return {
        padding: "4px 12px 4px 12px",
        minHeight: 24,
        minWidth: 78,
        fontSize: 10,
        borderRadius: "4px",
      };
    case ButtonSize.Small:
      return {
        padding: "8px 12px 8px 12px",
        minHeight: 32,
        minWidth: 86,
        fontSize: 12,
        borderRadius: "4px",
      };
    case ButtonSize.Medium:
      return {
        padding: "8px 16px 8px 16px",
        minHeight: 36,
        minWidth: 104,
        fontSize: 14,
        borderRadius: "6px",
      };
    case ButtonSize.Large:
      return {
        padding: "12px 16px 12px 16px",
        minHeight: 48,
        minWidth: 115,
        fontSize: 16,
        borderRadius: "6px",
      };
  }
};

const BaseButton = styled(MUIButton)(() => ({
  justifyContent: "center",
  alignItems: "center",
  textTransform: "none",
  fontWeight: 600,
  "&:hover": {
    fontWeight: 600,
  },
  "&.Mui-disabled": {
    fontWeight: 600,
    opacity: 0.3,
  },
}));

const Primary = styled(BaseButton)(({ theme }) => ({
  fontWeight: 400,
  backgroundColor: theme.palette.primary.main,
  color: theme.palette.common.white,
  "&:hover": {
    backgroundColor: theme.palette.primary.dark,
    color: theme.palette.common.white,
    fontWeight: 400,
  },
  "&.Mui-disabled": {
    color: theme.palette.common.white,
    backgroundColor: theme.palette.primary.light,
    fontWeight: 400,
    opacity: 0.9,
  },
}));

const Secondary = styled(BaseButton)(({ theme }) => ({
  backgroundColor: theme.palette.common.white,
  boxShadow: `0px 1px 2px 0px rgba(16, 24, 40, 0.06), 0px 1px 3px 0px rgba(16, 24, 40, 0.10)`,
  color: theme.palette.grey[900],
  border: `1px solid ${theme.palette.grey[100]}`,
  "&:hover": {
    color: theme.palette.grey[900],
    border: `1px solid ${theme.palette.grey[100]}`,
    backgroundColor: theme.palette.grey[50],
  },
  "&.Mui-disabled": {
    color: theme.palette.grey[900],
    backgroundColor: theme.palette.common.white,
    border: `1px solid ${theme.palette.grey[100]}`,
  },
}));

const Tertiary = styled(BaseButton)(() => ({
  backgroundColor: "none",
  color: linkMain,
  "&:hover": {
    color: "#232F9D", //theme.palette.link.hover,
    backgroundColor: "none",
  },
  "&.Mui-disabled": {
    color: linkMain,
    backgroundColor: "none",
  },
}));
const linkMain = "#3B4BDB";

const Dark = styled(BaseButton)(({ theme }) => ({
  backgroundColor: theme.palette.grey[700],
  color: theme.palette.common.white,
  "&:hover": {
    color: theme.palette.common.white,
    backgroundColor: theme.palette.grey[800],
  },
  "&.Mui-disabled": {
    color: theme.palette.common.white,
    backgroundColor: theme.palette.grey[700],
  },
}));

const Destructive = styled(BaseButton)(({ theme }) => ({
  backgroundColor: theme.palette.common.white,
  color: errorMain,
  border: `1px solid ${errorMain}`,
  "&:hover": {
    color: errorMain,
    border: `1px solid ${errorMain}`,
    backgroundColor: theme.palette.grey[50],
  },
  "&.Mui-disabled": {
    color: errorMain,
    border: `1px solid ${errorMain}`,
    backgroundColor: theme.palette.common.white,
  },
}));
const errorMain = "#F04438";
